/* eslint-disable import/no-dynamic-require */
import React, { FC } from 'react';
import styles from './ProductCard.module.scss';
import { Link } from 'react-router-dom';

interface LogoProps {
    title: string;
    source: string;
    screen: string;
}

const ProductCard: FC<LogoProps> = ({ title, source, screen }) => (
    <Link
        to={screen}
    >
        <div
            className={styles.container}
        >
            <img
                className={styles.image}
                src={source}
            />
            <div className={styles.title}>
                {title}
            </div>
        </div>
    </Link>
);
export default ProductCard;
