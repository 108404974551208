import React from 'react';
import styles from './WorkoutBackground.module.scss';
import image1 from '../../../assets/images/workout/1.png';
import image2 from '../../../assets/images/workout/2.png';
import image3 from '../../../assets/images/workout/3.png';
import image4 from '../../../assets/images/workout/4.png';
import image5 from '../../../assets/images/workout/5.png';
import image6 from '../../../assets/images/workout/6.png';
import image7 from '../../../assets/images/workout/7.png';
import image8 from '../../../assets/images/workout/8.png';
import image9 from '../../../assets/images/workout/9.png';
import image10 from '../../../assets/images/workout/10.png';
import image11 from '../../../assets/images/workout/11.png';
import image12 from '../../../assets/images/workout/12.png';
import image13 from '../../../assets/images/workout/13.png';
import image14 from '../../../assets/images/workout/14.png';
import image15 from '../../../assets/images/workout/15.png';
import image16 from '../../../assets/images/workout/16.png';
import image17 from '../../../assets/images/workout/17.png';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17];

const WorkoutBackground = () => (
    <div
        className={styles.container}
    >
        <div className={styles.imagesContainer}>
            {images.map((item, index) => (
                <img
                    style={{ zIndex: images.length - index }}
                    key={index}
                    className={styles.image}
                    src={item}
                />
            ))}
        </div>
        <div
            className={styles.overlay}
        />
    </div>
);

export default WorkoutBackground;
