import React, { useEffect } from 'react';
import styles from './LittleBitOfUniverseLanding.module.scss';
import ProductCard from '../components/cards/ProductCard';
import { useNavigate } from 'react-router-dom';

const LittleBitOfUniverseLanding = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/', { replace: true }); // Redirect to root without keeping history
    }, [navigate]);

    return (
        <div className={styles.container}>
            <div
                className={styles.contentContainer}
            >
                <div className={styles.title}>
                    Welcome to Little
                    <span className={styles.green}>bit</span>
                    of
                    <span className={styles.green}>Universe</span>
                </div>
                <div className={styles.description}>
                    Here are our products:
                </div>
            </div>
            <div className={styles.productsContainer}>
                <ProductCard
                    source={require('../assets/images/workout/WorkoutIcon.png')}
                    title="Little Bit of Workout"
                    screen="/workout"
                />
                <ProductCard
                    source={require('../assets/images/crochet/CrochetIcon.png')}
                    title="Crochet Counter"
                    screen="/crochet"
                />
            </div>
        </div>
    );
};

export default LittleBitOfUniverseLanding;
