/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import { Link } from 'react-router-dom';
import CrochetBackground from '../../components/layout/crochet/CrochetBackground';

const PrivacyPolicy = () => (
    <div className={styles.container}>
        <CrochetBackground />
        <div className={styles.largeTitle} id="1">
            Privacy Policy
        </div>
        In these Privacy Policy:
        <li>
            “we”, “us”, and “our” refer to LITTLE BIT OF UNIVERSE LIMITED, the company operating this App and the following websites:
            {' '}
            <Link to="https://littlebitofuniverse.com/crochet" className={styles.link}>
                https://littlebitofuniverse.com/crochet
            </Link>
            {' '}
            and
            {' '}
            <Link to="https://littlebitofuniverse.co.uk/crochet" className={styles.link}>
                https://littlebitofuniverse.co.uk/crochet
            </Link>
            .
        </li>
        <li>“you”, “your”, and “user” refer to the individual accessing or using the App or any of the websites mentioned in this document.</li>
        <br />
        Crochet Counter app and the websites values your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, share, and protect your information. It also explains your rights regarding your personal data under applicable laws, including the General Data Protection Regulation (GDPR).
        <br />
        <br />
        <div className={styles.title}>
            1. Data Controller
        </div>
        Crochet Counter app,
        {' '}
        <Link to="https://littlebitofuniverse.com/crochet" className={styles.link}>
            https://littlebitofuniverse.com/crochet
        </Link>
        {' '}
        and
        {' '}
        <Link to="https://littlebitofuniverse.co.uk/crochet" className={styles.link}>
            https://littlebitofuniverse.co.uk/crochet
        </Link>
        {' '}
        are operated by LITTLE BIT OF UNIVERSE LIMITED, registered in England.
        <br />
        <br />
        For inquiries, please contact us at:
        <br />
        <b>Email:</b>
        {' '}
        <span className={styles.email}>support@littlebitofuniverse.com</span>
        <br />
        <br />
        Under the GDPR, LITTLE BIT OF UNIVERSE LIMITED is the data controller responsible for processing your personal data.
        <br />
        <br />
        <div className={styles.title}>
            2. Scope of This Policy
        </div>
        This Privacy Policy applies to:
        <br />
        <br />
        <li>The Crochet Counter mobile application.</li>
        <li>
            The websites at
            {' '}
            <Link to="https://littlebitofuniverse.com/crochet" className={styles.link}>
                https://littlebitofuniverse.com/crochet
            </Link>
            {' '}
            and
            {' '}
            <Link to="https://littlebitofuniverse.co.uk/crochet" className={styles.link}>
                https://littlebitofuniverse.co.uk/crochet
            </Link>
            .
        </li>
        <br />
        Both platforms may collect personal data as outlined below.
        <br />
        <br />
        <div className={styles.title}>
            3. Legal Bases for Processing (GDPR)
        </div>
        Under the GDPR, we process your personal data based on the following legal grounds:
        <br />
        <br />
        <li>
            <b>
                Performance of a Contract:
                {' '}
            </b>
            To provide the services you request through the App or websites.
        </li>
        <li>
            <b>
                Consent:
                {' '}
            </b>
            When you provide explicit consent for specific data uses (e.g., marketing communications or health data processing).
        </li>
        <li>
            <b>
                Legal Obligation:
                {' '}
            </b>
            To comply with legal requirements.
        </li>
        <li>
            <b>
                Legitimate Interests:
                {' '}
            </b>
            To improve our services, ensure security, or conduct analytics, provided these interests do not override your rights.
        </li>
        <br />
        <br />

        <div className={styles.title}>
            4. Personal Data We Collect
        </div>
        We collect the following types of personal data when you use our App or websites:
        <br />
        <br />
        <b>a. Information Collected Through the App:</b>
        <li>
            <b>
                Account Information:
                {' '}
            </b>
            Name, email address, username, and password.
        </li>
        <li>
            <b>
                Project Data:
                {' '}
            </b>
            Details of your crochet projects, patterns saved, progress tracking, and any notes or images you upload.
        </li>
        <li>
            <b>
                Payment Information:
                {' '}
            </b>
            Credit/debit card details for purchases (processed via secure third-party payment providers).
        </li>
        <li>
            <b>
                Device and Usage Data:
                {' '}
            </b>
            IP address, device type, operating system, App usage metrics, and crash logs.
        </li>
        <li>
            <b>
                Community Interactions:
                {' '}
            </b>
            Comments, messages, or other content shared in forums, chatrooms, or social features within the app.
        </li>
        <br />
        <b>b. Information Collected Through the Websites:</b>
        <li>
            <b>
                Contact Forms:
                {' '}
            </b>
            Name, email address, and any other information you provide through forms.
        </li>
        <li>
            <b>
                Browsing Data:
                {' '}
            </b>
            IP address, browser type, pages visited, and referral sources.
        </li>
        <li>
            <b>
                Cookies and Tracking:
                {' '}
            </b>
            See Section 8 for details on cookies used on the websites.
        </li>
        <br />
        We may also collect optional information, such as user preferences for crochet techniques, favorite patterns, and participation in community events or challenges. This data helps personalize your experience within the App and websites.
        <br />
        <br />
        <div className={styles.title}>
            5. How We Use Your Data
        </div>
        We use your personal data for the following purposes:
        <li>
            <b>
                To Provide Services:
                {' '}
            </b>
            Facilitate workouts, fitness tracking, and websites functionality.
        </li>
        <li>
            <b>
                Account Management:
                {' '}
            </b>
            Manage your profile and user preferences.
        </li>
        <li>
            <b>
                Customer Support:
                {' '}
            </b>
            Respond to inquiries and resolve issues.
        </li>
        <li>
            <b>
                Payment Processing:
                {' '}
            </b>
            Process payments securely.
        </li>
        <li>
            <b>
                Personalization:
                {' '}
            </b>
            Provide tailored workout plans and recommendations.
        </li>
        <li>
            <b>
                Analytics:
                {' '}
            </b>
            Improve App and websites performance.
        </li>
        <li>
            <b>
                Marketing:
                {' '}
            </b>
            Send promotional emails or notifications with your consent.
        </li>
        <br />
        <br />
        <div className={styles.title}>
            6. Sharing Your Data
        </div>
        We do not sell your personal information. However, we may share your data with:
        <li>
            <b>
                Service Providers:
                {' '}
            </b>
            Payment processors, cloud storage services, analytics providers, and web hosting providers.
        </li>
        <li>
            <b>
                Business Partners:
                {' '}
            </b>
            Third-party platforms you choose to connect with, such as marketplaces for purchasing crochet supplies, social media platforms for sharing projects, or external pattern libraries.
        </li>
        <li>
            <b>
                Legal Authorities:
                {' '}
            </b>
            If required by law or for compliance with legal obligations.
        </li>
        <br />
        All third parties are bound by confidentiality agreements and data processing agreements in compliance with GDPR requirements.
        <br />
        <br />
        We use third-party services to enhance app functionality, analytics, and payment processing. These services may collect and process your data as outlined in their respective privacy policies:
        <div
            className={styles.linksContainer}
        >
            <div>
                {'\u2022  '}
                <a
                    className={styles.link}
                    href="https://policies.google.com/privacy"
                >
                    Google Play Services
                </a>
            </div>
            <div>
                {'\u2022  '}
                <a
                    className={styles.link}
                    href="https://firebase.google.com/support/privacy"
                >
                    Google Analytics for Firebase
                </a>
            </div>
            <div>
                {'\u2022  '}
                <a
                    className={styles.link}
                    href="https://firebase.google.com/support/privacy"
                >
                    Firebase Crashlytics
                </a>
            </div>
            <div>
                {'\u2022  '}
                <a
                    className={styles.link}
                    href="https://www.revenuecat.com/privacy/"
                >
                    RevenueCat
                </a>
            </div>
            <div>
                {'\u2022  '}
                <a
                    className={styles.link}
                    href="https://www.mongodb.com/legal/privacy/privacy-policy"
                >
                    MongoDB
                </a>
            </div>
        </div>
        <br />
        <div className={styles.title}>
            7. International Data Transfers (GDPR)
        </div>
        If your personal data is transferred outside the European Economic Area (EEA), we ensure appropriate safeguards are in place, such as:
        <li>Standard contractual clauses approved by the European Commission.</li>
        <li>Binding corporate rules.</li>
        <li>Your explicit consent for the transfer.</li>
        <br />
        <br />
        <div className={styles.title}>
            8. Cookies and Tracking Technologies
        </div>
        We use cookies and similar technologies on both the App and websites to enhance your experience:
        <br />
        <br />
        <b>a. App Cookies and Tracking:</b>
        <li>
            <b>
                Analytics Cookies:
                {' '}
            </b>
            Monitor App performance and usage.
        </li>
        <li>
            <b>
                Marketing Cookies:
                {' '}
            </b>
            Support personalized ads and promotions.
        </li>
        <br />
        <b>b. Websites Cookies:</b>
        <li>
            <b>
                Essential Cookies:
                {' '}
            </b>
            Enable core functionalities, such as user logins.
        </li>
        <li>
            <b>
                Analytics Cookies:
                {' '}
            </b>
            Analyze websites performance and usage statistics.
        </li>
        <li>
            <b>
                Advertising Cookies:
                {' '}
            </b>
            Serve targeted ads based on your browsing behavior.
        </li>
        <br />
        You can adjust your cookie preferences in your browser or App settings.
        <br />
        <br />
        <div className={styles.title}>
            9. Data Retention
        </div>
        We retain your personal data only as long as necessary for the purposes outlined in this Privacy Policy or to comply with legal obligations. Once your data is no longer needed, it will be anonymized or securely deleted.
        <br />
        <br />
        <div className={styles.title}>
            10. Your GDPR Rights
        </div>
        As an EU/EEA resident, you have the following rights under the GDPR:
        <br />
        <li>
            <b>
                Access:
                {' '}
            </b>
            Request a copy of your personal data
        </li>
        <li>
            <b>
                Rectification:
                {' '}
            </b>
            Correct inaccurate or incomplete data.
        </li>
        <li>
            <b>
                Erasure (“Right to Be Forgotten”):
                {' '}
            </b>
            Request deletion of your data, subject to legal obligations.
        </li>
        <li>
            <b>
                Restriction of Processing:
                {' '}
            </b>
            Limit how we use your data in certain circumstances.
        </li>
        <li>
            <b>
                Data Portability:
                {' '}
            </b>
            Receive your data in a structured, commonly used, and machine-readable format.
        </li>
        <li>
            <b>
                Objection:
                {' '}
            </b>
            Object to processing based on legitimate interests or for direct marketing purposes.
        </li>
        <li>
            <b>
                Automated Decision-Making:
                {' '}
            </b>
            Not be subject to decisions based solely on automated processing.
        </li>
        <br />
        To exercise these rights, contact us at
        {' '}
        <span className={styles.email}>support@littlebitofuniverse.com</span>
        {' '}
        <br />
        <br />
        <div className={styles.title}>
            11. Changes to This Policy
        </div>
        We may update this Privacy Policy periodically. You will be notified of significant changes via email, through the App, or on our websites. Please review the updated policy before continuing to use the App or websites.
        <br />
        <br />
        <div className={styles.title}>
            12. Contact Us
        </div>
        If you have questions or concerns about this Privacy Policy or wish to exercise your GDPR rights, please reach out to:
        <br />
        <b>Email:</b>
        {' '}
        <span className={styles.email}>support@littlebitofuniverse.com</span>
        <div className={styles.title}>
            Last Updated
        </div>
        Privacy Policy was last updated on 13 March 2025.
    </div>
);

export default PrivacyPolicy;
