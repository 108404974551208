import React from 'react';
import styles from './Contact.module.scss';

const Contact = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Contact
        </div>
        For inquiries or assistance, please contact us at
        {' '}
        <a className={styles.link} href="mailto:support@littlebitofuniverse.com">
            support@littlebitofuniverse.com
        </a>
        {' '}
        <br />
        We will get back to you as soon as possible and look forward to hearing from you.
    </div>
);

export default Contact;
