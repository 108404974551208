import React from 'react';
import styles from './CrochetBackground.module.scss';

const CrochetBackground = () => (
    <div
        className={styles.container}
    >
        <div>
            <div className={styles.wave} />
            <div className={styles.wave} />
            <div className={styles.wave} />
        </div>
    </div>
);

export default CrochetBackground;
