import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LittleBitOfUniverseLanding from '../screens/LittleBitOfUniverseLanding';

import WorkoutHomeLandingPage from '../screens/workout/HomeLandingPage';
import WorkoutTermsAndConditions from '../screens/workout/TermsAndConditions';
import WorkoutPrivacyPolicy from '../screens/workout/PrivacyPolicy';
import WorkoutCookiesPolicy from '../screens/workout/CookiesPolicy';
import WorkoutDataDeletion from '../screens/workout/DataDeletion';
import WorkoutContact from '../screens/workout/Contact';

import CrochetHomeLandingPage from '../screens/crochet/HomeLandingPage';
import CrochetTermsAndConditions from '../screens/crochet/TermsAndConditions';
import CrochetPrivacyPolicy from '../screens/crochet/PrivacyPolicy';
import CrochetCookiesPolicy from '../screens/crochet/CookiesPolicy';
import CrochetDataDeletion from '../screens/crochet/DataDeletion';
import CrochetContact from '../screens/crochet/Contact';

const AppNavigator = () => (
    <Routes>
        <Route path="/" element={<LittleBitOfUniverseLanding />} />

        <Route path="/workout" element={<WorkoutHomeLandingPage />} />
        <Route path="/workout/terms" element={<WorkoutTermsAndConditions />} />
        <Route path="/workout/privacy" element={<WorkoutPrivacyPolicy />} />
        <Route path="/workout/cookies" element={<WorkoutCookiesPolicy />} />
        <Route path="/workout/data-deletion" element={<WorkoutDataDeletion />} />
        <Route path="/workout/contact" element={<WorkoutContact />} />

        <Route path="/crochet/" element={<CrochetHomeLandingPage />} />
        <Route path="/crochet/terms" element={<CrochetTermsAndConditions />} />
        <Route path="/crochet/privacy" element={<CrochetPrivacyPolicy />} />
        <Route path="/crochet/cookies" element={<CrochetCookiesPolicy />} />
        <Route path="/crochet/data-deletion" element={<CrochetDataDeletion />} />
        <Route path="/crochet/contact" element={<CrochetContact />} />

        <Route path="*" element={<LittleBitOfUniverseLanding />} />
    </Routes>
);

export default AppNavigator;
