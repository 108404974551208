import React, { useEffect, useState } from 'react';
import styles from './HomeLandingPage.module.scss';
import PhoneFrame from '../../assets/images/Iphone-14-Pro-frame.png';
import Image1 from '../../assets/images/crochet/1.MyModels.png';
import Image2 from '../../assets/images/crochet/2.ComponentCreate.png';
import Image3 from '../../assets/images/crochet/3.ModelPreview.png';
import Image4 from '../../assets/images/crochet/4.ProjectCounting.png';
import Image5 from '../../assets/images/crochet/5.ProjectPreview.png';
import Image6 from '../../assets/images/crochet/6.SimpleCounters.png';
import Image7 from '../../assets/images/crochet/7.SimpleCounter.png';
import Image8 from '../../assets/images/crochet/8.LearnDetails.png';
import Image9 from '../../assets/images/crochet/9.LearnTab.png';
import Image10 from '../../assets/images/crochet/10.ComponentPreview.png';
import AndroidQRCode from '../../assets/images/crochet/qr-code-android.png';
import IOSQRCode from '../../assets/images/crochet/qr-code-ios.png';
import CrochetBackground from '../../components/layout/crochet/CrochetBackground';

const features = [
    '\u2022 Model Creation',
    '\u2022 Project progress tracking',
    '\u2022 Simple counters',
    '\u2022 Personal Models Stash',
    '\u2022 Learn Library',
];

const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
];

const HomeLandingPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setActiveIndex((prevIndex) => {
                if (prevIndex === images.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            });
        }, 1500);
        return () => clearInterval(id);
    }, []);

    return (
        <div className={styles.container}>
            <CrochetBackground />
            <div
                className={styles.phoneContainer}
            >
                <img
                    className={styles.phoneFrame}
                    src={PhoneFrame}
                />
                <img
                    className={styles.videoRec}
                    src={images[activeIndex]}
                />
            </div>
            <div
                className={styles.contentContainer}
            >
                <div className={styles.title}>
                    Welcome to
                    <span className={styles.purple}>Crochet Counter</span>
                </div>
                <div className={styles.description}>
                    Are you a creator in need of an app to create models while on the go?
                    <br />
                    A beginner looking for a way to keep track of your progress?
                    <br />
                    How about a seasoned crocheter looking for a way to keep track of your stash?
                    <br />
                    This is what Crochet Counter is.
                    <br />
                    <br />
                </div>
                <div className={styles.rowContainer}>
                    <div
                        className={styles.qrCodeContainer}
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.crochetcounter')}
                    >
                        <img
                            className={styles.qrCode}
                            src={AndroidQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nGoogle Play'}
                        </div>
                    </div>
                    <div
                        className={styles.featuresContainer}
                    >
                        <div className={styles.featuresTitle}>
                            App features:
                        </div>
                        {features.map((feature) => (
                            <div
                                key={feature}
                                className={styles.feature}
                            >
                                {feature}
                            </div>
                        ))}
                    </div>
                    <div
                        className={styles.qrCodeContainer}
                        onClick={() => window.open('https://apps.apple.com/gb/app/crochet-counter/id1666333136')}
                    >
                        <img
                            className={styles.qrCode}
                            src={IOSQRCode}
                        />
                        <div
                            className={styles.qrCodeText}
                        >
                            {'Download from\nApp Store'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeLandingPage;
