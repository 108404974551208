/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './TermsAndConditions.module.scss';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Terms and Conditions
        </div>
        <div className={styles.title}>
            Introduction and Agreement Overview
        </div>
        Welcome to Little Bit of Workout (the “App”), a fitness application designed to help you incorporate daily exercise into your routine. These Terms and Conditions (“Terms”) govern your use of the App and websites
        {' '}
        <Link to="https://littlebitofuniverse.com/workout" className={styles.link}>
            https://littlebitofuniverse.com/workout
        </Link>
        {' '}
        and
        {' '}
        <Link to="https://littlebitofuniverse.co.uk/workout" className={styles.link}>
            https://littlebitofuniverse.co.uk/workout
        </Link>
        , including any free trials, subscriptions, and related services offered through the App or websites (collectively, the “Services”). By downloading, installing, or using the App or websites, you agree to comply with and be bound by these Terms.
        <br />
        <br />
        In these Terms:
        <br />
        <li>
            “we”, “us”, and “our” refer to LITTLE BIT OF UNIVERSE LIMITED, the company operating this App and the following websites:
            {' '}
            <Link to="https://littlebitofuniverse.com/workout" className={styles.link}>
                https://littlebitofuniverse.com/workout
            </Link>
            {' '}
            and
            {' '}
            <Link to="https://littlebitofuniverse.co.uk/workout" className={styles.link}>
                https://littlebitofuniverse.co.uk/workout
            </Link>
            .
        </li>
        <li>“you”, “your”, and “user” refer to the individual accessing or using the App or any of the websites mentioned in this document.</li>
        <br />
        Please read these Terms carefully. They form a legally binding agreement between you and us. If you do not agree to these Terms, you must discontinue use of the App and websites.
        <br />
        <div className={styles.title}>
            Other Important Documents
        </div>
        Your use of the App and websites is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. Please review it alongside these Terms.
        <br />
        <br />
        <div className={styles.title}>
            Applicability and Use
        </div>
        These Terms apply regardless of the device or method you use to access the App and websites. If other individuals access the App or websites through your device, you are responsible for ensuring they understand and adhere to these Terms.
        <br />
        <br />
        <div className={styles.title}>
            Eligibility
        </div>
        You must be at least 18 years old (or meet the age of legal majority in your jurisdiction) to use the App and websites. By accepting these Terms, you confirm that you meet this requirement and that all information you provide is accurate and complete.
        <br />
        <br />
        <div className={styles.title}>
            About Little Bit of Workout
        </div>
        Little Bit of Workout is operated by LITTLE BIT OF UNIVERSE LIMITED, registered in England. The App is designed to incorporate simple and effective workout routines into your daily life. It offers a variety of exercise videos to help you stay active and motivated.
        <br />
        <br />
        <div className={styles.title}>
            Contacting Us
        </div>
        You can reach us for support or inquiries via email at
        {' '}
        <span className={styles.email}>support@littlebitofuniverse.com</span>
        {' '}
        If we need to contact you, we will use the email address you provided during account registration. Please ensure your contact information is accurate and up to date to receive important updates.
        <br />
        <br />
        <div className={styles.title}>
            Access to and Use of the App and websites
        </div>
        The App refers to the software application known as "Little Bit of Workout," available for download from the Apple App Store or Google Play Store (each an "App Store"). These Terms govern your use of the App and websites, which may be updated from time to time. Updated Terms will be posted on the App and websites, and we will make reasonable efforts to notify you of any changes. By accessing or using the App or websites at any time in the future, you agree to the updated Terms.
        <br />
        <br />
        We may modify, update, or discontinue the App and websites at our sole discretion and without prior notice to you. While we strive to ensure that the content of the App is accurate and up-to-date, we do not guarantee its accuracy or completeness. The App may become temporarily unavailable for maintenance or other reasons, and we do not guarantee uninterrupted or continuous access to it. Support provided by us is for informational purposes only, and we may, at our discretion, decide not to reply to any queries or provide support related to the App.
        <br />
        <br />
        To use the App, you must register for an account ("Account"). By creating an Account, you confirm that:
        <li>You are at least 18 years old or the legal age in your jurisdiction to enter into binding agreements.</li>
        <li>Any information you provide during registration is accurate and up to date.</li>
        <br />
        Your Account is for personal use only and cannot be shared or used by multiple individuals simultaneously. You are not allowed to create or operate more than one Account at a time.
        <br />
        <br />
        You must not misuse the App or assist others in doing so. Misuse includes, but is not limited to:
        <li>Introducing viruses, worms, trojans, or other malicious code.</li>
        <li>Attempting to gain unauthorized access to the App or its servers.</li>
        <li>Circumventing security measures or testing the App’s vulnerabilities.</li>
        <br />
        Violations may result in immediate termination of your access and legal action. If you breach this provision, we may report the incident to law enforcement authorities and disclose any personal information we hold about you.
        <br />
        <br />
        The App may contain links to third-party websites or resources. These links are provided for your convenience, and we have no control over the content or availability of these third-party sites. Access these sites at your own risk; we are not liable for issues arising from your interaction with them.
        <br />
        <br />
        We may collect, use, and store data you provide through the App, as detailed in our
        {' '}
        <Link to="/privacy" className={styles.link} target="blank">
            Privacy Policy
        </Link>
        <br />
        <br />
        <div className={styles.title}>
            Customer Responsibilities When Using the App
        </div>
        You agree to adhere to the following responsibilities:
        <li>Do not use the App for unlawful, immoral, abusive, discriminatory, or harmful purposes.</li>
        <li>Avoid actions that could damage the reputation of LITTLE BIT OF UNIVERSE LIMITED, their employees or its partners.</li>
        <li>Refrain from using obscene, vulgar, or offensive language.</li>
        <li>Avoid posting links to third-party websites with content that violates these Terms.</li>
        <br />
        You must not misuse the App in a way that could impair its performance or functionality. This includes:
        <li>Sending unauthorized mass communications (e.g., spam).</li>
        <li>Introducing viruses, trojans, worms, or other harmful material.</li>
        <li>Attempting unauthorized access to the App’s servers or databases.</li>
        <br />
        You are responsible for:
        <li>Providing accurate and up-to-date account information.</li>
        <li>Not using the App for commercial purposes, except as permitted by these Terms.</li>
        <li>Avoiding attempts to create competing products or services.</li>
        <br />
        Linking to the App is permitted if done fairly and legally, without harming our reputation. Links must not imply endorsement or affiliation unless agreed upon by us.
        If you are provided with security credentials, such as a password, you must keep them confidential. Notify us immediately if you suspect unauthorized access to your account. We are not liable for unauthorized account use unless it results from our negligence.
        In terms of the Service Provider's responsibility for your use of the application, it is important to note that while they strive to ensure that it is updated and accurate at all times, they do rely on third parties to provide information to them so that they can make it available to you. The Service Provider accepts no liability for any loss, direct or indirect, that you experience as a result of relying entirely on this functionality of the application.
        <br />
        <br />
        <div className={styles.title}>
            Health Disclaimer
        </div>
        The App is designed to provide general fitness guidance and is not a substitute for professional medical advice. You should consult your physician before beginning any exercise program, especially if you have pre-existing medical conditions or concerns. Using the App is at your own risk, and we are not responsible for any injuries or health issues that arise from following the guides, workouts, exercises, challenges, video instructions or any advice provided through the App or websites.
        <br />
        <br />
        <div className={styles.title}>
            Subscription Fees and Payment
        </div>
        The App is free to download, but certain features may require a paid subscription available through the App Store or Google Play. By subscribing, you agree to:
        <li>Provide accurate and up-to-date billing information.</li>
        <li>Comply with the payment policies of the App Store or Google Play.</li>
        <br />
        <div className={styles.subtitle}>
            Subscription Policies:
        </div>
        <li>Subscriptions renew automatically unless cancelled at least 24 hours before the renewal date.</li>
        <li>Refunds or pro-rated fees are not provided for failure to cancel on time.</li>
        <li>Trial offers may be modified or terminated at any time without notice.</li>
        <br />
        <div className={styles.subtitle}>
            Cancellations and Refunds:
        </div>
        <li>To avoid charges, cancel at least 24 hours before your subscription renews via your app store account settings.</li>
        <li>Deleting the App does not cancel your subscription or trial. Follow the cancellation process outlined above.</li>
        <br />
        <br />
        <div className={styles.title}>
            Termination Policy
        </div>
        We reserve the right to suspend or terminate your account and access to the App at any time, with or without cause, and with or without notice. Common reasons for termination include, but are not limited to:
        <li>Violation of these Terms</li>
        <li>Illegal or fraudulent activities</li>
        <li>Misuse of the App’s services</li>
        <br />
        Upon termination, your right to use the App will immediately cease. If your account is terminated due to a breach of these Terms, you will not be entitled to a refund for any unused portion of a subscription.
        <br />
        <br />
        <div className={styles.title}>
            Intellectual Property Rights
        </div>
        We own or license all intellectual property rights associated with the App and its content (“App Content”). This includes text, graphics, designs, logos, photos, videos, user interfaces, and software. These works are protected by copyright laws worldwide.
        <br />
        You may not use, copy, modify, distribute, or publicly display the App Content without prior written consent unless expressly authorized by these Terms or applicable law. Automated tools for data scraping or gathering are strictly prohibited.
        <br />
        <br />
        <div className={styles.title}>
            Limitation of Liability
        </div>
        We provide the App and Services on an "as is" basis without warranties of any kind. To the fullest extent permitted by law, we are not liable for:
        <li>Indirect, incidental, or consequential damages</li>
        <li>Loss of data, profits, or goodwill</li>
        <li>Damages caused by events beyond our control</li>
        <br />
        If liability limitations are deemed invalid, our aggregate liability shall not exceed the total subscription fees paid in the 12 months preceding the event giving rise to liability.
        <br />
        <br />
        <div className={styles.title}>
            Feedback and Suggestions
        </div>
        By submitting feedback, you grant us a royalty-free, worldwide license to use and incorporate your suggestions into our App and Services.
        <br />
        <br />
        <div className={styles.title}>
            Governing Law
        </div>
        These Terms are governed by the laws of England and Wales, and any disputes will be resolved exclusively in its courts.
        <br />
        <br />
        <div className={styles.title}>
            Last Updated
        </div>
        These Terms and Conditions were last updated on 19 January 2025.
    </div>
);

export default TermsAndConditions;
