import React, { FC } from 'react';
import styles from './WorkoutLogo.module.scss';

interface LogoProps {
    style?: React.CSSProperties;
}

const WorkoutLogo: FC<LogoProps> = ({ style }) => (
    <div
        className={styles.container}
    >
        <img
            className={styles.image}
            src={require('../assets/images/workout/logo.png')}
            style={style}
        />
    </div>
);
export default WorkoutLogo;
