/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import styles from './NavBar.module.scss';
import Logo from '../Logo';
import WorkoutLogo from '../WorkoutLogo';
import CrochetLogo from '../CrochetLogo';
import { Link, useLocation } from 'react-router-dom';

type PrefixesType = 'crochet' | 'workout';

const LogoComponents: Record<PrefixesType, React.ComponentType> = {
    workout: WorkoutLogo,
    crochet: CrochetLogo,
};

const NavBar = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    // @ts-ignore
    const pathPrefix: PrefixesType = currentPath.split('/')[1] || '';

    const LocalLogoComponent = pathPrefix && LogoComponents?.[pathPrefix];

    return (
        <div className={pathPrefix ? styles?.[`${pathPrefix}Container`] : styles.container}>
            <div className={pathPrefix ? styles?.[`${pathPrefix}NavbarBackground`] : styles.navbarBackground}>
                <div className={pathPrefix ? styles?.[`${pathPrefix}CompanyLogoContainer`] : styles.companyLogoContainer}>
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>
                <div className={pathPrefix ? styles?.[`${pathPrefix}SecondaryLogoContainer`] : styles.secondaryLogoContainer}>
                    {LocalLogoComponent && (
                        <Link to={`/${pathPrefix}`}>
                            <LocalLogoComponent />
                        </Link>
                    )}
                </div>
                {/* Do not delete the next div */}
                <div />
            </div>
            <div className={pathPrefix ? styles?.[`${pathPrefix}Shadow`] : styles.shadow} />
        </div>
    );
};

export default NavBar;
