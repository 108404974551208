import React from 'react';
import styles from './Footer.module.scss';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const pathPrefix = currentPath.split('/')[1] || '';

    if (!pathPrefix) {
        return null;
    }

    return (
        <div
            className={styles.container}
        >
            <div className={pathPrefix ? styles?.[`${pathPrefix}Shadow`] : styles.shadow} />
            <div className={pathPrefix ? styles?.[`${pathPrefix}NavbarBackground`] : styles.navbarBackground}>
                <Link to={`${pathPrefix}/`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Home
                    </div>
                </Link>
                <Link to={`${pathPrefix}/terms`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Terms and conditions
                    </div>
                </Link>
                <Link to={`${pathPrefix}/privacy`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Privacy Policy
                    </div>
                </Link>
                <Link to={`${pathPrefix}/cookies`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Cookies Policy
                    </div>
                </Link>
                <Link to={`${pathPrefix}/data-deletion`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Data Deletion
                    </div>
                </Link>
                <Link to={`${pathPrefix}/contact`}>
                    <div className={pathPrefix ? styles?.[`${pathPrefix}Text`] : styles.text}>
                        Contact
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Footer;
